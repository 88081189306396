.Intro {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  overflow-x: hidden;
  background-color: var(--white);
  font-family: "OUTFIT";  
}

.intro-container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 74%;

}
.intro-container .intro-main-heading {
  font-size: 3rem;
  color: var(--darkblue);
  font-weight: 600;
  font-family: 'OUTFIT';
  letter-spacing:1px;
    text-transform: uppercase;

}
.intro-container .intro-main-heading::after {
  content: "";
  display: block;
  width: 70px;
  height: 4px;
  background: var(--rose);
  left: 85px;
  margin: 0 auto 0 auto;
}
.intro-container .intro-main-paragraph {
  font-size: 1rem;
  word-spacing: 110%;
  letter-spacing: 1px;
  padding: 1.4rem 1.4rem;
  text-align: center;
  line-height: 1.2rem;
  color: var(--darkblue);
}
.intro-container .intro-main-hastags{
  color: var(--darkblue);
  text-align: center;
  
}

.social-container {
  position: relative;
  width: 400px;
  text-align: center;
  /* z-index: -6; */
}

.social-icons {
  padding: 0;
  list-style: none;
  margin: 1em;
}

.social-icons li {
  display: inline-block;
  margin: 0.15em;
  position: relative;
  font-size: 1.2em;
}

.social-icons i {
  color: var(--darkblue);
  position: absolute;
  top: 7px;
  left: 15px;
  transition: all 265ms ease-out;
}

.social-icons a {
  display: inline-block;
}

.social-icons a:before {
  content: " ";
  width: 45px;
  height: 45px;
  border-radius: 100%;
  display: block;
background-color: var(--lightblue);
  transition: all 265ms ease-out;
}

.social-icons a:hover:before {
  transform: scale(0);
  transition: all 265ms ease-in;
}
.social-icons a:hover i {
  color: var(--mixblue);
  background: -webkit-linear-gradient(45deg, var(--black), var(--wine));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 265ms ease-in;
}
.intro-images {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  position: relative;
  /* z-index: -4; */
  /* background-color: var(--wine); */

  padding: 8rem 0;
  margin-bottom: 3rem;
}
.img-1,.img-3 {
  bottom: 3rem;
}
.image_wrapper {
  position: relative;
}

.image_wrapper img {
  display: block;
  object-fit: cover;
  width: 15rem;
  height: 13rem;
  border-radius:50%;
  /* border:2px solid black */
  /* box-shadow: rgba(, 0.09) 0px 3px 12px; */
}

.image_wrapper .overlay {
  position: absolute;

  /* background: rgba(234, 170, 164, 0.6); */
  /* background: var(--wine); */
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.overlay_1 {
  left: 0;
  bottom: 0;
  width: 20%;
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
}

.image_wrapper h3 {
  color: rgb(0, 0, 0);
  position: relative;
top: 6rem;
text-align: center;
font-weight: 600;
}

.intro-svg{
  position: relative;
  bottom:13rem;
  z-index: -5;
}


@media only screen and (max-width: 1547px) {
  .intro-svg{
   
    bottom:9rem;

  }
  
}
@media only screen and (max-width: 1158px) {
  .intro-svg{
   
    bottom:5rem;

  }
  
  
}

@media only screen and (max-width: 1074px) {
  .intro-container{
    padding:0 5px
  }
  .intro-container .intro-main-heading {
  margin-top: 4rem;
  }
  .intro-container .intro-main-heading {
    font-size: 2.6rem;
   
  }
 
}

@media only screen and (max-width: 992px) {
  .intro-container {
    width: 90%;
  }

 
}

@media only screen and (max-width: 768px) {
  .intro-images {
    flex-wrap: wrap;
    align-items: center;
  }
  .img-1,
  .img-3 {
    bottom: 0px;
  }

  .intro-images {
    gap: 20px;
    position: relative;
    bottom: 55px;
  }
  .intro-container {
    width: 100%;
    position: relative;
    bottom: 53px
  }
  .intro-container .intro-main-heading{
    text-align: center; 
    font-size: 2.1rem;
  }
 
}

@media only screen and (max-width: 622px) {
  .intro-svg{
   
    bottom:2rem;

  }
  
  .intro-main-heading {
    text-align: center;
  }
  .intro-container .heading2 {
    text-align: center;
  }
}

@media only screen and (max-width: 570px) {
  .intro-main-heading {
    text-align: center;
  }
  .intro-container .intro-main-heading {
    /* font-size: 2rem; */
    /* color: var(--white); */
    font-weight: bold;
  }

}

@media only screen and (max-width: 475px) {
  

  
}
