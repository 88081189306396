.right-section {
  width: 50%;
  display: flex;
  justify-content: center;
  font-family: "OUTFIT";
}

.right-section-txt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin-right: 0.6rem;
  width: 100%;
}

.right-section-txt span:nth-of-type(1) {
  color: var(--green);
  font-weight: 600;
  font-family: "OUTFIT";
  font-size: 24px;
}
.right-section-txt span:nth-of-type(2) {
  font-size: 47px;
  font-weight: 500;
  font-family: "OUTFIT";
  color: var(--black);
  text-align: left;
  /* letter-spacing: 2px; */
  /* word-spacing: 4px; */
}
.right-section-txt span:nth-of-type(2)::after {
  content: "";
  display: block;
  width: 60px;
  height: 3px;
  background: var(--green);
  left: 85px;
  margin: 13px 0 13px 0;
}
.right-section-txt span:nth-of-type(3) {
  color: var(--grey);
  margin-bottom: 1rem;
  font-family: "outfitlight";
}
.right-section .checkmark .checkmark-txt {
  font-size: 16px; /* modify to test */
  margin-bottom: 0.5em;
  list-style-type: none;
  padding: 0.25em 0.88em 0 0.88em;
  position: relative;
  font-family: "OUTFIT";
  overflow: hidden;
  display: flex;
  gap: 6px;
}

.checkmark-txt img {
  width: 17px;
  height: 17px;
  position: relative;
  top: 4px;
 
}

/* .checkmark .checkmark-txt:before {
    content: " ";
    display: block;
    border: solid .8em var(--green);
    border-radius: .8em;
    height: 0;
    width: 0;
    position: absolute;
    left: .5em;
    top: 40%;
    margin-top: -.5em;
  }
  
  .checkmark .checkmark-txt:after {
    content: " ";
    display: block;
    width: .3em;
    height: .6em;
    border: solid #fff;
    border-width: 0 .2em .2em 0;
    position: absolute;
    left: 1.2em;
    top: 45%;
    margin-top: -.2em;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  } */
@media screen and (max-width: 416px) {
  .right-section .checkmark {
    font-size: 16px; /* modify to test */
    margin-bottom: 0.5em;
    list-style-type: none;

    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
  .right-section-txt span:nth-of-type(1) {
    text-align: center;
  }
  .right-section-txt span:nth-of-type(2) {
    font-size: 30px;
    font-weight: 600;

    color: black;
    text-align: center;
  }
  .right-section-txt span:nth-of-type(2)::after {
    content: "";
    display: block;
    width: 40px;
    height: 3px;
    background: var(--green);
    position: relative;
    left: 150px;

    margin: 13px 0 13px 0;
    text-align: center;
  }
  .right-section-txt span:nth-of-type(3) {
    color: var(--grey);
    margin-bottom: 1rem;
    text-align: center;
  }
}


@media screen and (max-width: 813px) {
  .right-section {
   width: 100%;
  }
}