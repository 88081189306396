.Navbar-container {
  background-color: var(--darkblue);
  font-family: "outFIT";
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  padding: 0 1rem;
  background-color: var(--darkblue);
  color: var(--green);
  z-index: 999;
  width: 100%;
  position: inherit;
  /* z-index: 99; */
  overflow: hidden;
}

.nav-logo {
  position: relative;
  left: 80px;
  /* top:3px; */
  width: 82px;
  height: 87px
}

nav {
  position: relative;
  bottom: -8px;
  overflow: revert-layer;
  margin-left: auto;
}

nav a {
  margin: 0 0.7rem;
  color: var(--lightblue);
  text-decoration: none;
  font-weight: 500;
  font-family: "outFIT";
  font-size: 17px;
}
/* nav a:last-child{ */
  /* color: var(--darkblue); */
/* } */
nav a:hover {
  text-decoration: none;
  color: var(--white);
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;

  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

.nav-bar-btn {
  color: var(--lightblue);
  background-color: var(--lightblue);
}

.nav-close-btn {
  color: white;
}

header div .nav-center {
  display: flex;
  align-items: center;
}

header div .nav-end {
  display: flex;
  align-items: flex-end;
}

/* donate now */

header a {
  display: inline-block;
  /* margin: 30px; */
  position: relative;
  text-decoration: none;
  font-style: 15px;
}

nav .donate {
  border: 1px solid var(--mixblue);
  background: var(--darkblue);
  background-position: 0 0;
  background-size: 200% 100%;
  border-radius: 8px;
  color: var(--lightblue);
  padding: 17.5px 38px 8px 8px;
  /* text-shadow: 1px 1px 5px #666; */
  transition: all 250ms;
  height: max-content;
  width: max-content;
  overflow-y: hidden;
  margin-top: 14px;
  margin-bottom: 9px;
  position: relative;
  top: 21px;
}

.donate::after {
  border-left: 1px solid darken(#00684d, 5%);
  color: darken(#00684d, 10%);
  content: "\2764";
  /* font-family: "FontAwesome"; */
  font-size: 30px;
  padding: 10px 0;
  padding-left: 15px;
  position: absolute;
  right: 18px;
  top: -2px;
  transition: all 500ms 300ms;
}

.donate:hover {
  background-position: -100% 0;
  color: var(--green);
  border-radius: 80px;
}

.donate:hover::after {
  border-left: 1px solid lighten(#00684d, 5%);
  color: red;

}

@media only screen and (max-width: 1480px) {
  .nav-logo {
    position: relative;
    /* left: 10px; */
  }

  nav .join-now-btn {
    position: relative;
    left: 70%;
  }

  header .nav-logo {
    width: 5rem;
    height: 5rem;
  }
}

@media only screen and (max-width: 1300px) {
  nav a {
    margin: 0rem 0.5rem;
  }
}

@media only screen and (max-width: 1250px) {
  nav {
    margin-top: 10px;
  }

  nav .join-now-btn {
    position: relative;
    left: 50%;
  }

  nav .donate::after {
    top: 4px;
    right: 21px;
  }
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: -102vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    z-index: 999;

    background-color: var(--darkblue);
    transition: 1s;
  }

  header .responsive_nav {
    transform: translateY(100vh);
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
    color: var(--white);
  }

  nav .join-now-btn {
    position: relative;
    left: 0%;
  }

  nav .donate::after {
    top: 6px;
    right: 18px;
  }
}

@media only screen and (max-width: 999px) {
.nav-logo{
  left:22px
  
}
}
@media only screen and (max-width: 410px) {
  header .nav-logo {
    width: 4rem;
    height: 4rem;
  }
}