.Joinus-Section{
    /* margin: 3rem 0 8rem 0; */
    font-family: 'OUTFIT';
    /* margin: 3rem 0; */
margin-top: 3rem;
margin-bottom: 3rem;
}
.Testimonial-section-heading{
color: #372948;
text-align: center;
margin: 3rem 0 3rem 0;
}
.slick-slide > div{
    margin:0 5px;
}
.slick-list{
    /* margin: 0 -10px; */

}
.Joinus-Section .joinus-card{
    height:fit-content;
    max-height: 45rem;
    padding: 16px;
    border:2px solid var(--darkblue);
    border-radius: 11px;
    transition: var(--transition);
    /* background-color: var(--peach-color); */
    /* background-color: #4b2121; */
    /* border:2px solid #372948; */
  
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;}

.Joinus-Section .joinus-card-bottom{
    text-align: center;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}
.Joinus-Section .joinus-card-bottom h6{
    color: var(--darkblue);
    font-size: 17px;
}

.Joinus-Section .joinus-card-bottom p{
    font-size: 13px;
    color:var(--mixblue)
}

.slick-slider
{
    overflow: hidden;
}