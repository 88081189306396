
@font-face {
  font-family:'OUTFIT';
  src:url(.././public/Fonts/Outfit-SemiBold.ttf);
  
}
  
  
  @font-face {
    font-family:'outfit';
    src:url(.././public/Fonts/Outfit-Regular.ttf);

  }

    @font-face {
      font-family:'outFIT';
      src:url(.././public/Fonts/Outfit-Medium.ttf);
      }

      @font-face {
        font-family:'outfitlight';
        src:url(.././public/Fonts/Outfit-Light.ttf);
        }
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: 'Outfit', sans-serif; */
    overflow-x: hidden;
}
html , body{
  /* overflow-x: hidden; */
  scroll-behavior: smooth;

}
:root {
	--lightblue:#BBE1FA;
	--grey: #626262;
	--black: #1B262C;
	
  --white:#ffffff;
  --darkgrey:#ebecf0;
  --mixblue:#3282B8;
 
  --darkblue:#0F4C75;

}


a{
    text-decoration: none;
}
